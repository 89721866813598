import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import ItemList from "./components/Item/ItemList";
import Sidebar from "./components/Sidebar/Sidebar";
import ViewData from "./components/ViewData";
import "./styles/App.css";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <Sidebar />
        <Switch>
          <Route exact path="/" component={ItemList} />
          <Route exact path="/market" component={() => <ViewData targetView={"Market"} />} />
          <Route exact path="/vendor" component={() => <ViewData targetView={"Vendor"} />} />
          <Route exact path="/stock" component={() => <ViewData targetView={"Stock"} />} />
        </Switch>
      </Fragment>
    );
  }
}

export default App;
