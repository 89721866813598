import React, { Fragment, useEffect, useState } from "react";
import Item from "./Item";
import axios from 'axios';

export default function ItemList() {
    const [items, setItems] = useState([]);

    // GET request to get data from heroku
    useEffect(() => {
        axios.get('https://stg-inventory.herokuapp.com/item').then(res => {
            setItems({ items: res.data.data });
        });
    });

    let data = items;

    // List of all the items to display
    var itemlist = [];
    if (Object.keys(data).length) {
        for (var i = 0; i < Object.keys(data).length; i++) {
            itemlist.push(<Item itemName={data.items[i].name} quantity={data.items[i].quantity} key={data.items[i]._id} avg={data.items[i].avgCostPrice} code={data.items[i].code} last={data.items[i].lastSellPrice} />)
        }
    }

    return (
        <Fragment>
            <div className="container-second" id="container-second">
                <div className="items">
                    {itemlist}

                </div>
            </div>
        </Fragment>
    );
};
