import React from 'react';
import "./Item.css";
import { NavLink } from "react-router-dom";

export default function Item(props) {

    return (
        <div className="item">
            <div className="item-header">
                <div className="price">
                    {props.itemName}
                </div>
                <div className="title">$ {props.avg}</div>
            </div>
            <ul type="none" className="item-list">
                <li>Sell Price: <strong>6</strong></li>
                <div className="border"></div>
                <li>Available Quantity: <strong>{props.quantity}</strong></li>
                <div className="border"></div>
                <li>Last Sell Price: <strong>{props.last}</strong></li>
                <div className="border"></div>
                <li>Code: <strong>{props.code}</strong></li>
                <NavLink to="/#">ORDER NOW!</NavLink>
            </ul>
        </div>
    );
}
