import { Button, Table, OverlayTrigger, Popover } from "react-bootstrap";

export default function TableComponent({ viewData, viewRefData }) {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          {viewData.columnInformation &&
            viewData.columnInformation.map((column, index) => <th key={index}>{column.name}</th>)}
        </tr>
      </thead>
      <tbody>
        {viewData.data &&
          viewData.data.map((e, index) => (
            <tr key={index}>
              {viewData.columnInformation &&
                viewData.columnInformation.map((column, i) => (
                  <td key={i}>
                    {column.type === "array"
                      ? e[column.objKey] && e[column.objKey].join(", ")
                      : column.type === "referencedSchema"
                      ? viewRefData.hasOwnProperty(column.objKey) &&
                        viewRefData[column.objKey].hasOwnProperty("data") &&
                        viewRefData[column.objKey].data.find((x) => x._id === e[column.objKey]) && (
                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                              <Popover id="popover-basic">
                                <Popover.Title as="h3">
                                  {viewRefData[column.objKey].data.find((x) => x._id === e[column.objKey]).name}
                                </Popover.Title>
                                <Popover.Content>
                                  {Object.keys(
                                    viewRefData[column.objKey].data.find((x) => x._id === e[column.objKey])
                                  ).map((p, i) => (
                                    <p key={i}>
                                      {p} : {viewRefData[column.objKey].data.find((x) => x._id === e[column.objKey])[p]}
                                    </p>
                                  ))}
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <Button variant="outline-dark">
                              {viewRefData[column.objKey].data.find((x) => x._id === e[column.objKey]).code +
                                " - " +
                                viewRefData[column.objKey].data.find((x) => x._id === e[column.objKey]).name}
                            </Button>
                          </OverlayTrigger>
                        )
                      : e[column.objKey]}
                  </td>
                ))}
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
