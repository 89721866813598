import axios from "axios";
import { CommonLoading } from "react-loadingg";
import React, { useState, useEffect, Fragment } from "react";
import { Container, Button } from "react-bootstrap";

import "./index.css";
import { baseUrl } from "../../common/apiRoutes";
import TableComponent from "../Views/Table";
import FormComponent from "../Form/Form";

async function fetchData(endPoint) {
  try {
    let res = await axios.get(baseUrl + endPoint);
    res = res.data;
    let resRefData = {};
    let referencedSchema =
      res.columnInformation && res.columnInformation.filter((obj) => obj.type === "referencedSchema");
    if (referencedSchema) {
      try {
        const resRefDataArr = await Promise.all(referencedSchema.map((e) => axios.get(baseUrl + e.objKey)));
        referencedSchema.map((e, i) => (resRefData[e.objKey] = resRefDataArr[i].data));
      } catch (error) {
        console.log(` --------------------------------`);
        console.log(`file: index.jsx ~ line 23 ~ error`, error);
        console.log(` --------------------------------`);
      }
    }
    return { res, resRefData };
  } catch (error) {
    console.log(` ----------------------------------------`);
    console.log(`file: index.jsx ~ line 42 ~ error`, error);
    console.log(` ----------------------------------------`);
  }
}

export default function ViewDataComponent({ targetView }) {
  const [viewData, setViewData] = useState({});
  const [viewRefData, setViewRefData] = useState({});
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  try {
    useEffect(() => {
      async function fetchDataAndUpdateState() {
        const response = await fetchData(targetView.toLowerCase());
        setViewData(response.res);
        setViewRefData(response.resRefData);
      }
      fetchDataAndUpdateState();
    }, [targetView]);
  } catch (error) {
    console.log(` ----------------------------------------`);
    console.log(`file: index.jsx ~ line 52 ~ error`, error);
    console.log(` ----------------------------------------`);
  }

  const handleShow = () => {
    setErrorMessage("");
    setShow(true);
  };

  const handleClose = () => {
    setErrorMessage("");
    setShow(false);
  };

  if (viewData && viewData.data) {
    return (
      <Fragment>
        <Container className="container-second" id="container-second">
          <h1 className="heading pb-4">{targetView}</h1>
          <div className="pb-4">
            <h5 className="count">
              Total {targetView}(s) : {viewData.total}
            </h5>
            <Button variant="dark" onClick={handleShow}>
              Add New {targetView}
            </Button>
            <FormComponent
              targetView={targetView}
              show={show}
              setShow={setShow}
              viewData={viewData}
              setViewData={setViewData}
              viewRefData={viewRefData}
              setViewRefData={setViewRefData}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              handleClose={handleClose}
              fetchData={fetchData}
            />
          </div>
          <TableComponent viewData={viewData} viewRefData={viewRefData} />
        </Container>
      </Fragment>
    );
  } else return <CommonLoading size={"large"} />;
}
