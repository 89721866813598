import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";

export default function Sidebar() {
  const [sidebar, setSidebar] = useState(false); // To toggle Sidebar

  const changeSidebar = () => {
    setSidebar(!sidebar);
    document.getElementById("listy").classList.toggle("active");
    if (document.getElementById("container-second")) {
      document.getElementById("container-second").classList.toggle("active");
    }
  };

  return (
    <div id="sidebar" className={sidebar ? "sidebar active" : "sidebar"}>
      <div className="toggle-btn" onClick={changeSidebar}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className="listy" id="listy">
        <li className="listItem first">
          <NavLink className="linky" to="/" onClick={changeSidebar}>
            Home
          </NavLink>
        </li>
        <li className="listItem">
          <NavLink className="linky" to="/dashboard" onClick={changeSidebar}>
            Dashboard
          </NavLink>
        </li>
        <li className="listItem">
          <NavLink className="linky" to="/market" onClick={changeSidebar}>
            Markets
          </NavLink>
        </li>
        <li className="listItem">
          <NavLink className="linky" to="/vendor" onClick={changeSidebar}>
            Vendors
          </NavLink>
        </li>
        <li className="listItem">
          <NavLink className="linky" to="/stock" onClick={changeSidebar}>
            Stock
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
